import React from "react"
import { Link } from "gatsby"

export default props => {
  console.log(props)
  return (
    <article>
      <Link to={"/blog/".concat(props.post.frontmatter.slug)}>
        <h2>{props.post.frontmatter.title}</h2>
      </Link>
      <p>
        {props.post.excerpt}
      </p>
      {props.post.frontmatter.date && (
        <p>
          Pubblicato il
          <time dateTime={props.post.frontmatter.date}>
            {new Date(props.post.frontmatter.date).toLocaleDateString()}
          </time>
        </p>
      )}
    </article>
  )
}
