import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Excerpt from '../components/blogExcerpt';
import SEO from "../components/seo";

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    return (
        <Layout>
            <SEO title="Blog articles" />
            <h1>Articoli</h1>
            {posts.map(post => (<Excerpt key={post.node.id} post={post.node} />))}
        </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark( 
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            slug
            title
            date
          }
        }
      }
    }
  }
`
